import React from 'react'
import { useTranslation } from '../../i18n'
import {
  Categories,
  LinkToCategory,
  Icon,
  Wrapper,
  FoodList,
  GroupTitle,
  GroupItems,
  FoodGroupWrapper,
  DishTitle,
  DishDescription,
  DishWrapper
} from './css'

const Category = ({ category, icon, index }) => (
  <LinkToCategory href={`#foodGroup_${index}`}>
    <Icon filename={icon} />
    {category}
  </LinkToCategory>
)

const FoodGroup = ({ category, icon, items, index }) => (
  <FoodGroupWrapper id={`foodGroup_${index}`}>
    <GroupTitle>
      <Icon filename={icon} />
      {category}
    </GroupTitle>
    <GroupItems>
      {items.map(dish => (
        <Dish key={dish.title} {...dish} />
      ))}
    </GroupItems>
  </FoodGroupWrapper>
)

const Dish = ({ title, description }) => (
  <DishWrapper>
    <DishTitle>{title}</DishTitle>
    {description && <DishDescription>{description}</DishDescription>}
  </DishWrapper>
)

const Food = () => {
  const { t } = useTranslation()
  const foodList = t('food')
  return (
    <Wrapper>
      <Categories>
        {foodList.map((foodGroup, index) => (
          <Category
            index={index}
            key={foodGroup.category}
            category={foodGroup.category}
            icon={foodGroup.icon}
          />
        ))}
      </Categories>
      <FoodList>
        {foodList.map((foodGroup, index) => (
          <FoodGroup key={foodGroup.category} index={index} {...foodGroup} />
        ))}
      </FoodList>
    </Wrapper>
  )
}

export default Food
