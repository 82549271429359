import React from 'react'
import Layout from '../layout'
import Food from '../screens/Food'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Food />
  </Layout>
)

export default IndexPage
