import styled from 'styled-components'
import Img from '../../components/Img'
import { primaryFont } from '../../GlobalStyles'

export const Wrapper = styled.div`
  color: #1a1818;
  ${primaryFont};
`

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0;
`

export const LinkToCategory = styled.a`
  display: flex;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  color: #1a1818;
  text-transform: lowercase;
`

export const Icon = styled(Img).attrs({
  style: { position: 'absolute' }
})`
  width: 30px;
  margin-right: 15px;
  left: -35px;
  top: 50%;
  transform: translate(0, -50%);
`

export const FoodList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
`

export const FoodGroupWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
`

export const GroupTitle = styled.h2`
  ${primaryFont};
  position: relative;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: inline-block;
`

export const GroupItems = styled.div``

export const DishWrapper = styled.div`
  margin-bottom: 20px;
`

export const DishTitle = styled.h3`
  ${primaryFont};
  font-size: 22px;
  white-space: pre-line;
  margin: 0;
`
export const DishDescription = styled.h4`
  ${primaryFont};
  font-size: 17px;
  display: inline-block;
  white-space: pre-line;
  margin: 0;
`
